import React from "react";
import "../css/landing.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Header from "./header.js";
import Footer from "./footer.js";

export default function Terms() {
  return (
    <Container fluid className="top-container">
      <Header />
      <Row style={{ padding: 20, color: "#eeeef1" }}>
        <h2>Terms and Conditions</h2>

        <p>Version 1.8, updated 19 Feb 2024</p>

        <p>
          These Terms and Conditions ("Terms") govern your use of Snagm Digital
          Coupons ("App"), developed by Bold Intermedia LLC ("Developer"). By
          downloading, installing, or using the App, you agree to be bound by
          these Terms. If you do not agree to these Terms, do not use the App.
        </p>

        <h4>Definitions</h4>

        <p>
          1. "User" refers to any person who downloads, installs, or uses the
          App.
        </p>

        <p>
          2. "Content" refers to any text, images, video, audio, or other media
          available through the App.
        </p>

        <h4>License</h4>

        <p>
          Subject to your compliance with these Terms, the Developer grants you
          a limited, non-exclusive, non-transferable, revocable license to
          download, install, and use the App for your personal, non-commercial
          purposes.
        </p>

        <h4>User Conduct</h4>

        <p>You agree not to:</p>
        <Row style={{ paddingLeft: 30 }}>
          <ul>
            <li>Use the App for any unlawful or fraudulent purposes.</li>
            <li>
              Copy, modify, adapt, or create derivative works of the App or its
              Content.
            </li>
            <li>
              Interfere with, disrupt, or overload the App or its underlying
              infrastructure.
            </li>
            <li>
              Attempt to gain unauthorized access to the App or any associated
              systems or networks.
            </li>
          </ul>
        </Row>

        <h4>Intellectual Property Rights</h4>

        <p>
          All rights, title, and interest in and to the App, including its
          Content and any associated intellectual property rights, are the
          exclusive property of the Developer and its licensors. You may not
          reproduce, distribute, or create derivative works of the App or its
          Content without the express written permission of the Developer.
        </p>

        <h4>Feedback and Suggestions</h4>

        <p>
          Any feedback or suggestions you provide regarding the App or its
          Content shall become the exclusive property of the Developer. The
          Developer may use, reproduce, disclose, and distribute any feedback or
          suggestions for any purpose without restriction or compensation to
          you. You hereby assign to the Developer all rights, title, and
          interest in and to any feedback or suggestions you provide.
        </p>

        <h4>Disclaimer</h4>

        <p>
          THE APP IS PROVIDED "AS IS" AND "AS AVAILABLE," WITHOUT WARRANTIES OF
          ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES
          OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. THE DEVELOPER DOES NOT WARRANT THAT THE APP WILL BE
          UNINTERRUPTED, ERROR-FREE, OR COMPLETELY SECURE.
        </p>

        <h4>Limitation of Liability</h4>

        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE DEVELOPER SHALL
          NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR
          EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE
          APP, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>

        <h4>Governing Law</h4>

        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of Bexar County, Texas, without regard to its conflict of laws
          principles.
        </p>

        <h4>Modifications</h4>

        <p>
          The Developer reserves the right to modify these Terms at any time, in
          its sole discretion. Your continued use of the App following any
          modification constitutes your acceptance of the modified Terms.
        </p>

        <h4>Contact Information</h4>

        <p>
          If you have any questions or concerns about these Terms or the App,
          please contact us at: legal@boldintermedia.com
        </p>
      </Row>
      <Footer />
    </Container>
  );
}
