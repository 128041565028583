import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import logo from "../assets/snagm-logo.png";
import "../css/header.css";

export default function Header() {
  return (
    <Row className="admin-header">
      <Col>
        <a href="/">
          <Image className="admin-logo" src={logo} />
        </a>
      </Col>
      <Col className="d-flex justify-content-end"></Col>
    </Row>
  );
}
