import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import logo from "../assets/snagm-logo.png";
import "../css/lightHeader.css";

export default function LightHeader() {
  return (
    <Row className="video-admin-row">
      <Col className="video-admin-col">
        <a href="/">
          <Image className="video-admin-logo" src={logo} />
        </a>
      </Col>
    </Row>
  );
}
