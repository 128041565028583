import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Header from "./header";
import "../css/bizSignup.css";

export default function AdSignup() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const bodyData = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      restaurantName: formData.restaurantName,
      restaurantZip: formData.restaurantZip,
      inviteCode: formData.inviteCode,
    };
    const bodyString = JSON.stringify(bodyData);
    await fetch(
      "https://us-central1-snagm-b6068.cloudfunctions.net/adSignupForm",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyString,
      }
    )
      .then((res) => {
        if (res.status === 200) {
          navigate("/advertise");
        } else {
          alert(
            "There was an error submitting your form. Please try again later."
          );
        }
      })
      .catch((err) => {
        alert(
          "There was an error submitting your form. Please try again later."
        );
      });
  };

  return (
    <Container fluid className="signup-container">
      <Header />
      <Row className="center-row">
        <Row className="middle-row">
          <Col xs={12} className="signup-column">
            <Form className="form-style">
              <Row className="title-row">
                <p className="title-text">Let's get started!</p>
              </Row>
              <Row className="generic-center">
                <p className="subtitle-text">
                  We just need a few details to get you set up:
                </p>
              </Row>
              <Row className="form-row">
                <Col xs={12} className="form-column">
                  <Form.Group>
                    <Form.Label>Your Name</Form.Label>
                    <Form.Control
                      className="form-input"
                      id="name"
                      type="text"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="form-row">
                <Col xs={12} className="form-column">
                  <Form.Group>
                    <Form.Label>Your Email Address</Form.Label>
                    <Form.Control
                      className="form-input"
                      id="email"
                      type="text"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="form-row">
                <Col xs={12} className="form-column">
                  <Form.Group>
                    <Form.Label>Your Phone Number</Form.Label>
                    <Form.Control
                      className="form-input"
                      id="phone"
                      type="text"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="form-row">
                <Col xs={12} className="form-column">
                  <Form.Group>
                    <Form.Label>Business Name</Form.Label>
                    <Form.Control
                      className="form-input"
                      id="restaurantName"
                      type="text"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="form-row">
                <Col xs={12} className="form-column">
                  <Form.Group>
                    <Form.Label>Business Zip Code</Form.Label>
                    <Form.Control
                      className="form-input"
                      id="restaurantZip"
                      type="text"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="form-row">
                <Col xs={12} className="form-column">
                  <Form.Group>
                    <Form.Label>Invite Code (optional)</Form.Label>
                    <Form.Control
                      className="form-input"
                      id="inviteCode"
                      type="text"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="bottom-row">
                <p className="bottom-text">
                  We'll respond by email within 1 business day. We look forward
                  to working with you!
                </p>
              </Row>
              <Row className="button-row">
                <Button
                  onClick={(e) => handleFormSubmit(e)}
                  className="submit-button"
                >
                  Submit
                </Button>
              </Row>
            </Form>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}
