import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/landing.css";
import "react-activity/dist/library.css";
import hero from "../assets/snagm-hero.png";
import apple from "../assets/apple-black.svg";
import android from "../assets/google-play.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Header from "./header";
import Footer from "./footer";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Dots } from "react-activity";
import ReactGA from "react-ga4";

export default function Landing() {
  const navigate = useNavigate();
  const [zipcode, setZipcode] = useState("");
  const [found, setFound] = useState(false);
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [isChecking, setIsChecking] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [thanks, setThanks] = useState(false);

  useEffect(() => {
    const verifyUS = async () => {
      try {
        const response = await fetch("https://api.country.is");
        const data = await response.json();
        console.log("verified country: ", data.country);
        if (data.country !== "US" && data.country !== "MX") {
          navigate("/sorry");
        }
      } catch (err) {
        //-- do nothing
      }
    };
    verifyUS();
  }, [navigate]);

  const notifyLater = async () => {
    //-- make sure the email includes a @ symbol and a period
    if (email.includes("@") === false || email.includes(".") === false) {
      return;
    }
    setIsSubmitting(true);
    const bodyData = {
      email: email,
      zip: zipcode,
    };
    const bodyString = JSON.stringify(bodyData);
    await fetch(
      "https://us-central1-snagm-b6068.cloudfunctions.net/notifyLater",
      {
        method: "POST",
        body: bodyString,
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .catch((error) => {
        console.log(error);
      });
    setEmail("");
    setZipcode("");
    setChecked(false);
    setFound(false);
    setIsSubmitting(false);
    setThanks(true);
  };

  const getZipResult = async () => {
    setIsChecking(true);
    ReactGA.event("zipcode_check");
    let finalData = {
      result: "INVALID",
    };
    const bodyData = {
      zipcode: zipcode,
    };
    const bodyString = JSON.stringify(bodyData);
    await fetch("https://us-central1-snagm-b6068.cloudfunctions.net/checkZip", {
      method: "POST",
      body: bodyString,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        finalData = data;
      })
      .catch((error) => {
        return finalData;
      });
    setIsChecking(false);
    return finalData;
  };

  const changeZipcode = (newText) => {
    const text = newText.currentTarget.value;
    if (text.length > 5 || isNaN(text)) {
      return;
    }
    setZipcode(text);
  };

  const changeEmail = (newText) => {
    const text = newText.currentTarget.value;
    if (text.length > 50) {
      return;
    }
    setEmail(text);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const zipResult = await getZipResult();
    if (zipResult.result === "FOUND") {
      setFound(true);
    }
    setChecked(true);
  };

  return (
    <Container fluid className="top-container">
      <Header />
      <Row className="hero-section">
        <Col
          lg={true}
          className="hero-box d-flex flex-column justify-content-center"
        >
          <Row>
            <h1 className="title-font">
              Coupons you want from places you love
            </h1>
          </Row>
          <Row>
            <h2 className="subtitle-font">
              Subscribe to your favorite places to get regular discounts,
              birthday deals, exclusive offers, and more!
            </h2>
          </Row>
          {!found && (
            <React.Fragment>
              <Row>
                <h4
                  style={{
                    color: "#EEEEF1",
                    marginTop: 20,
                    textAlign: "center",
                  }}
                >
                  Enter your zip code to check availability:
                </h4>
              </Row>
              <Row className="d-flex">
                <Col className="flex-column bigger">
                  <Row className="d-flex justify-content-center align-items-center">
                    <Form.Control
                      name="zip-input"
                      className="zip-input"
                      autoFocus
                      type="text"
                      value={zipcode}
                      onChange={(newText) => changeZipcode(newText)}
                    />
                    {isChecking ? (
                      <Button className="button-zip" variant="custom">
                        <Dots color="#ffffff" />
                      </Button>
                    ) : (
                      <Button
                        className="button-zip"
                        onClick={handleSubmit}
                        variant="custom"
                      >
                        Check
                      </Button>
                    )}
                  </Row>
                </Col>
              </Row>
            </React.Fragment>
          )}
          {thanks && (
            <Row>
              <h5
                style={{ color: "#EEEEF1", marginTop: 40, textAlign: "center" }}
              >
                Thanks, we'll let you know!
              </h5>
            </Row>
          )}
          {!found && checked && (
            <Row className="d-flex justify-content-center">
              <h5
                style={{
                  color: "#EEEEF1",
                  marginTop: 20,
                  marginBottom: 20,
                  textAlign: "center",
                }}
              >
                Sorry, we are not available in your area yet.
              </h5>
              <p
                style={{
                  color: "#EEEEF1",
                  textAlign: "center",
                  fontSize: 24,
                }}
              >
                Want to know as soon as we are?
              </p>
              <Form.Control
                style={{
                  width: 240,
                  textAlign: "center",
                  marginTop: 10,
                }}
                type="email"
                value={email}
                onChange={(newText) => changeEmail(newText)}
                placeholder="Enter email"
              />
              <Button
                style={{
                  width: 120,
                  textAlign: "center",
                  marginTop: 10,
                  marginLeft: 1,
                }}
                type="submit"
                variant="info"
                onClick={notifyLater}
                disabled={isSubmitting}
              >
                Notify Me
              </Button>
            </Row>
          )}
          {found && (
            <React.Fragment>
              <Row>
                <h5
                  style={{
                    color: "#EEEEF1",
                    marginTop: 20,
                    textAlign: "center",
                  }}
                >
                  Available in your area - download the app now!
                </h5>
              </Row>
              <Row className="downloads">
                <Col className="download-box-landing">
                  <a
                    href="https://apps.apple.com/us/app/snagm-digital-coupons/id6473562680"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image className="download-apple-landing" src={apple} />
                  </a>
                </Col>
                <Col className="download-box-landing">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.snagm.app"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image className="download-android-landing" src={android} />
                  </a>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </Col>
        <Col lg={true} className="hero-box">
          <Image className="hero-image img-fluid" src={hero} />
        </Col>
      </Row>
      <Footer />
    </Container>
  );
}
