import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import LightHeader from "./lightHeader";
import "../css/bizSignup.css";
import ReactGA from "react-ga4";

const emptyForm = {
  name: "",
  email: "",
  phone: "",
  restaurantName: "",
  restaurantZip: "",
};

export default function WaitList() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(emptyForm);
  const [inviteCode, setInviteCode] = useState("");
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [fieldReady, setFieldReady] = useState({
    name: false,
    email: false,
    phone: false,
    restaurantName: false,
    restaurantZip: false,
  });

  useEffect(() => {
    const verifyUS = async () => {
      try {
        const response = await fetch("https://api.country.is");
        const data = await response.json();
        if (data.country !== "US" && data.country !== "MX") {
          navigate("/sorry");
        }
      } catch (err) {
        //-- do nothing
      }
    };
    verifyUS();
    const queryParameters = new URLSearchParams(window.location.search);
    const invite = queryParameters.get("invite");
    if (invite) {
      setInviteCode(invite);
    }
  }, [navigate]);

  const handleFormChange = (e) => {
    const target = e.target.id;
    const value = e.target.value;
    const readyFields = { ...fieldReady };
    if (value.length > 64) {
      return;
    }
    if (value.length > 0) {
      readyFields[target] = true;
    } else {
      readyFields[target] = false;
    }
    if (target === "restaurantZip") {
      if (isNaN(value) || value.length > 5) {
        return;
      }
      if (value.length === 5) {
        readyFields.restaurantZip = true;
      } else {
        readyFields.restaurantZip = false;
      }
    }
    if (target === "email") {
      if (value.includes("@") && value.includes(".")) {
        readyFields.email = true;
      } else {
        readyFields.email = false;
      }
    }
    if (target === "phone") {
      if (value.length > 18) {
        return;
      }
      const phoneValue = value.replace(/\D/g, "");
      if (phoneValue.length >= 10) {
        readyFields.phone = true;
      } else {
        readyFields.phone = false;
      }
    }
    setFormData({ ...formData, [target]: value });
    setFieldReady(readyFields);
    handleFormValidation(readyFields);
  };

  const handleInviteCodeChange = (e) => {
    setInviteCode(e.target.value);
  };

  const handleFormValidation = (readyFields) => {
    if (
      readyFields.name &&
      readyFields.email &&
      readyFields.phone &&
      readyFields.restaurantName
    ) {
      setReadyToSubmit(true);
    } else {
      setReadyToSubmit(false);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setReadyToSubmit(false);
    ReactGA.event("signup");
    const bodyData = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      restaurantName: formData.restaurantName,
      inviteCode: !inviteCode ? "none" : inviteCode,
    };
    const bodyString = JSON.stringify(bodyData);
    await fetch(
      "https://us-central1-snagm-b6068.cloudfunctions.net/signupForm",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyString,
      }
    )
      .then((res) => {
        if (res.status === 200) {
          navigate("/offer?email=" + formData.email);
        } else {
          alert(
            "There was an error submitting your form. Please try again later."
          );
        }
      })
      .catch((err) => {
        alert(
          "There was an error submitting your form. Please try again later."
        );
      });
  };

  return (
    <Container fluid className="signup-container">
      <LightHeader />
      <Row className="center-row">
        <Row className="middle-row">
          <Col xs={12} className="signup-column">
            <Form className="form-style">
              <Row className="title-row">
                <p className="title-text">Please Bear With Us</p>
              </Row>
              <Row style={{ marginBottom: 20 }} className="generic-center">
                <p className="subtitle-text">
                  Due to unexpected demand, we have had to start a waitlist for
                  our service. Please enter your email address below and we will
                  contact you as soon as we can get started. We apologize for
                  the delay.
                </p>
              </Row>
              <Row className="form-row">
                <Col xs={12} className="form-column">
                  <Form.Group>
                    <Form.Label>Your Name</Form.Label>
                    <Form.Control
                      className={fieldReady.name ? "form-ready" : "form-input"}
                      id="name"
                      type="text"
                      onChange={(e) => handleFormChange(e)}
                      value={formData.name}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="form-row">
                <Col xs={12} className="form-column">
                  <Form.Group>
                    <Form.Label>Your Email Address</Form.Label>
                    <Form.Control
                      className={fieldReady.email ? "form-ready" : "form-input"}
                      id="email"
                      type="text"
                      onChange={(e) => handleFormChange(e)}
                      value={formData.email}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="form-row">
                <Col xs={12} className="form-column">
                  <Form.Group>
                    <Form.Label>Your Phone Number</Form.Label>
                    <Form.Control
                      className={fieldReady.phone ? "form-ready" : "form-input"}
                      id="phone"
                      type="text"
                      onChange={(e) => handleFormChange(e)}
                      value={formData.phone}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="form-row">
                <Col xs={12} className="form-column">
                  <Form.Group>
                    <Form.Label>Restaurant Name</Form.Label>
                    <Form.Control
                      className={
                        fieldReady.restaurantName ? "form-ready" : "form-input"
                      }
                      id="restaurantName"
                      type="text"
                      onChange={(e) => handleFormChange(e)}
                      value={formData.restaurantName}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="form-row">
                <Col xs={12} className="form-column">
                  <Form.Group>
                    <Form.Label>Invite Code (optional)</Form.Label>
                    <Form.Control
                      className={readyToSubmit ? "form-ready" : "form-input"}
                      id="inviteCode"
                      type="text"
                      value={inviteCode}
                      onChange={(e) => handleInviteCodeChange(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="button-row">
                <Button
                  disabled={!readyToSubmit}
                  onClick={(e) => handleFormSubmit(e)}
                  className="submit-button"
                  variant="custom"
                >
                  Submit Form
                </Button>
              </Row>
              <Row className="bottom-row">
                <p className="bottom-text">
                  Thank you for your interest. We look forward to working with
                  you!
                </p>
              </Row>
            </Form>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}
