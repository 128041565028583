import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "../css/footer.css";
import veteran from "../assets/veteran-owned.png";

export default function Footer() {
  return (
    <React.Fragment>
      <Row className="footer-section">
        <Col lg={true} className="footer-box">
          <Row>
            <p style={{ margin: 0, textAlign: "center" }}>
              Copyright &#64; 2024 Bold Intermedia LLC.
            </p>
          </Row>
          <Row>
            <p style={{ margin: 0 }}>All Rights Reserved.</p>
          </Row>
        </Col>
        <Col lg={true} className="footer-box">
          <Row>
            <p style={{ marginBottom: 0, textAlign: "center" }}>
              <a className="footer-link" href="https://snagm.com/privacy">
                Privacy Policy
              </a>
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              <a className="footer-link" href="https://snagm.com/terms">
                Terms of Use
              </a>
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              <a
                href="mailto:%77%65%62%40%73%6e%61%67%6d%2e%63%6f%6d"
                className="footer-link"
              >
                Contact
              </a>
            </p>
          </Row>
          <Row>
            <p style={{ marginBottom: 0, textAlign: "center" }}>
              <a className="footer-link" href="https://snagm.com/restaurants">
                For Your Restaurant
              </a>
            </p>
          </Row>
        </Col>
        <Row className="veteran-row">
          <Image src={veteran} className="veteran-image" />
        </Row>
      </Row>
    </React.Fragment>
  );
}
