import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import LightHeader from "./lightHeader";
import "../css/landing.css";

function GetEmail() {
  const url = window.location.href;
  const email = url.split("?email=")[1];
  return email;
}

export default function Unsub() {
  useEffect(() => {
    const processUnsubscribe = async () => {
      const email = GetEmail();
      const body = JSON.stringify({ email: email });
      await fetch(
        "https://us-central1-snagm-b6068.cloudfunctions.net/processUnsub",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: body,
        }
      );
    };
    processUnsubscribe();
  }, []);

  return (
    <React.Fragment>
      <LightHeader />
      <Col className="nopage-col">
        <h1 className="nopage-title">Unsubscribed</h1>
        <p className="nopage-subtext">
          You won't receive any more marketing emails from us.
        </p>
        <p className="nopage-subtext nopage-subtext-bottom">
          If you change your mind in the future, please contact us
          at:&nbsp;&nbsp;
          <b>support@snagm.com</b>.
        </p>
      </Col>
    </React.Fragment>
  );
}
