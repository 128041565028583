import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../css/scans.css";
import "react-activity/dist/library.css";
import { Dots } from "react-activity";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Container } from "react-bootstrap";
import apple from "../assets/apple-black.svg";
import android from "../assets/google-play.png";

const webURL = "https://storage.googleapis.com/snagm-b6068.appspot.com/images/";
const snagmLogo =
  "https://storage.googleapis.com/snagm-b6068.appspot.com/public/snagm-logo-blue-small.jpg";

export default function Scan() {
  const { scancode } = useParams();
  const [bannerURL, setBannerURL] = useState("");
  const [clientName, setClientName] = useState("");
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const sendCode = async () => {
      let cleanedCode = scancode.substring(0, 5);
      cleanedCode = cleanedCode.replace(/[^0-9a-z]/gi, "");
      cleanedCode = cleanedCode.toUpperCase();
      const bodyData = {
        scancode: cleanedCode,
      };
      const bodyString = JSON.stringify(bodyData);
      let finalData = {};
      await fetch(
        "https://us-central1-snagm-b6068.cloudfunctions.net/codeScan",
        {
          method: "POST",
          body: bodyString,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          finalData = data;
          const result = finalData.result;
          if (result === "ERROR") {
            setNotFound(true);
            setLoading(false);
            return;
          }
          const clientData = finalData.clientData;
          const client = clientData.data;
          setBannerURL(webURL + client.logo);
          setClientName(client.clientName);
          setLoading(false);
        })
        .catch((error) => {
          return finalData;
        });
    };
    sendCode();
  }, [scancode]);

  if (loading) {
    return (
      <Container fluid style={{ backgroundColor: "#2a5ca6" }}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col className="scan-column">
            <Row>
              <Image src={snagmLogo} fluid style={{ padding: 20 }} />
            </Row>
            <Row style={{ marginTop: 60 }}>
              <Dots color="#ffffff" size={32} />
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

  if (notFound) {
    return (
      <Container fluid style={{ backgroundColor: "#2a5ca6" }}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col className="scan-column">
            <Row>
              <Image src={snagmLogo} fluid style={{ padding: 20 }} />
            </Row>
            <Row>
              <p
                style={{
                  textAlign: "center",
                  marginBottom: 20,
                  fontSize: 22,
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                Oops! We couldn't find that code. Please check and try again.
              </p>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid style={{ backgroundColor: "#2a5ca6" }}>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Col className="scan-column">
          <Row>
            <Image
              src={snagmLogo}
              fluid
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                marginTop: 30,
                marginBottom: 15,
              }}
            />
          </Row>
          <Row className="main-text-row">
            <p style={{ textAlign: "center", marginBottom: 20, fontSize: 22 }}>
              Subscribe to your favorite places to get regular discounts,
              birthday deals, exclusive offers, and more!
            </p>
          </Row>
          <Row>
            <Image src={bannerURL} fluid />
          </Row>
          <Row
            style={{
              display: "flex",
              paddingTop: 20,
            }}
          >
            <h4 style={{ textAlign: "center", fontSize: 22, fontWeight: 600 }}>
              {clientName}
            </h4>
            <p style={{ textAlign: "center", fontSize: 20 }}>
              Subscribe Code: <strong>{scancode}</strong>
            </p>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <p
              style={{
                textAlign: "center",
                margin: 0,
                fontSize: 22,
                fontWeight: 600,
              }}
            >
              It's easy and free!
            </p>
          </Row>
          <Row style={{ marginTop: 10, paddingLeft: 20, paddingRight: 20 }}>
            <p style={{ textAlign: "center", margin: 0, fontSize: 18 }}>
              1. Download Snagm Digital Coupons
            </p>
          </Row>
          <Row style={{ marginTop: 10, paddingLeft: 20, paddingRight: 20 }}>
            <p style={{ textAlign: "center", margin: 0, fontSize: 18 }}>
              2. Create your account (30 seconds)
            </p>
          </Row>
          <Row style={{ marginTop: 10, paddingLeft: 20, paddingRight: 20 }}>
            <p style={{ textAlign: "center", margin: 0, fontSize: 18 }}>
              3. Scan the QR code again, or enter the subscribe code above
            </p>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <a
              href="https://play.google.com/store/apps/details?id=com.snagm.app"
              target="_blank"
              rel="noreferrer"
            >
              <Image className="download-scan-android" src={android} />
            </a>
          </Row>
          <Row style={{ paddingBottom: "40px" }}>
            <a
              href="https://apps.apple.com/us/app/snagm-digital-coupons/id6473562680"
              target="_blank"
              rel="noreferrer"
            >
              <Image className="download-scan-apple" src={apple} />
            </a>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
