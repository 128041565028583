import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LightHeader from "./lightHeader";
import Image from "react-bootstrap/Image";
import "../css/landing.css";

export default function WaitThanks() {
  return (
    <React.Fragment>
      <LightHeader />
      <Col className="thanks-col">
        <Row>
          <h1 className="thanks-main-title">Thanks again!</h1>
          <p className="nopage-subtext">We'll contact you ASAP.</p>
          <p className="nopage-subtext">
            Start thinking about the first coupons you want to create!
          </p>
        </Row>
        <Image
          src="https://storage.googleapis.com/snagm-b6068.appspot.com/public/phone_shot.jpg"
          alt="thank you image"
          className="thanks-image"
        />
        <p className="nopage-subtext">
          You can&nbsp;
          <a className="thanks-link" href="https://snagm.com">
            click here
          </a>
          &nbsp;to download the app.
        </p>
        <Row style={{ marginBottom: 40 }}>
          <p className="thanks-subtext">&nbsp;</p>
        </Row>
      </Col>
    </React.Fragment>
  );
}
