import React from "react";
import "../css/landing.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Header from "./header.js";
import Footer from "./footer.js";

export default function Privacy() {
  return (
    <Container fluid className="top-container">
      <Header />
      <Row style={{ padding: 20, color: "#eeeef1" }}>
        <h2>Privacy Policy</h2>

        <p>Version 1.4, updated 8 Jan 2024</p>

        <p>
          Bold Intermedia LLC develops and operates the Snagm Digital Coupons
          ("App").
        </p>

        <p>
          This page is to inform users regarding our policies with the
          collection, use, and disclosure of personal information while using
          the App.
        </p>

        <p>
          If you choose to use our App, then you agree to the collection and use
          of information in relation with this policy. We will not use or share
          your information with anyone except as described in this Privacy
          Policy.
        </p>

        <h4>Information Collection and Use</h4>

        <p>
          We collect and store all personal information related to your App
          profile, which you voluntarily give us either upon sign-up, or through
          continued use of the App. This information includes:
        </p>
        <Row style={{ paddingLeft: 30 }}>
          <ul>
            <li>Your First Name, (voluntary) for app personalization</li>
            <li>
              Your Zip Code, (voluntary) so that we may filter content relevant
              to your location
            </li>
            <li>
              Your Day of Birth - without the year (voluntary) so that we know
              when to send you birthday coupons
            </li>
            <li>
              Your Phone Number, (voluntary) so that we can identify and
              authenticate your account
            </li>
            <li>
              Some usage information, (continued use) such as the types of
              coupons you seem to be interested in, so that we can improve what
              we show you
            </li>
          </ul>
        </Row>

        <h4>Performance and Diagnostic Information</h4>

        <p>
          We want to inform you that whenever you visit our App, we collect
          information about the performance of the App. This is not linked to
          your identity and is used only to improve the App.
        </p>

        <h4>Third Parties</h4>

        <p>
          We do not share any of the information we collect with any third
          parties. We use Google Analytics to collect app performance data, but
          this data is not linked to your identity.
        </p>

        <h4>Data Retention and Deletion</h4>

        <p>
          We will retain your information for as long as your account is active.
          If your account is inactive for a period of 3 years, your account will
          be deleted. You may delete your account at any time. To do so, please
          visit: https://snagm.com/delete
        </p>

        <h4>Security</h4>

        <p>
          We value your trust in providing us your personal information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.
        </p>

        <h4>Children's Privacy</h4>

        <p>
          Our App does not address anyone under the age of 13. We do not
          knowingly collect personal identifiable information from children
          under 13. In the case we discover that a child under 13 has provided
          us with personal information, we will immediately delete this from our
          servers. If you are a parent or guardian and you are aware that your
          child has provided us with personal information, please contact us so
          that we will be able take necessary actions.
        </p>

        <h4>Changes to This Privacy Policy</h4>

        <p>
          We may update this Privacy Policy from time to time. Thus, we advise
          you to review this page periodically for any changes. We will notify
          you of any changes by posting the new Privacy Policy on this page.
          These changes are effective immediately, as they are posted on this
          page.
        </p>

        <h4>Contact Us</h4>

        <p>
          If you have any questions or concerns about our Privacy Policy or the
          App, please contact us at: legal@boldintermedia.com
        </p>
      </Row>
      <Footer />
    </Container>
  );
}
