import React from "react";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "../css/adStart.css";

export default function AdStart() {
  const navigate = useNavigate();

  const linkSignup = () => {
    navigate("/startads");
  };

  return (
    <React.Fragment>
      <Row className="start-section">
        <Col xs={12} lg={6} className="center-column">
          <Button
            onClick={() => linkSignup()}
            className="start-block"
            size="lg"
            variant="custom"
          >
            Sign Up - 60 Seconds
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
}
