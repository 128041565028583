import React from "react";
import Col from "react-bootstrap/Col";
import LightHeader from "./lightHeader";
import "../css/landing.css";

export default function Sorry() {
  return (
    <React.Fragment>
      <LightHeader />
      <Col className="nopage-col">
        <h1 className="nopage-title">Sorry</h1>
        <p className="nopage-subtext">
          We're currently only able to work with restaurants in the United
          States.
        </p>
        <p className="nopage-subtext nopage-subtext-bottom">
          We hope to expand to other countries in the future. Thank you for your
          interest!
        </p>
      </Col>
    </React.Fragment>
  );
}
