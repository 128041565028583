import React from "react";
import "../css/landing.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Header from "./header.js";
import Footer from "./footer.js";
import Image from "react-bootstrap/Image";
import example from "../assets/example.jpg";

export default function Delete() {
  return (
    <Container fluid className="top-container">
      <Header />
      <Row style={{ padding: 20, color: "#eeeef1" }}>
        <h2>
          <strong>Delete Your Account</strong>
        </h2>
        <p>
          You can delete your account from the Snagm Digital Coupons app at any
          time. When you delete your account, profile information including your
          name, birthday, subscriptions, and coupons will be immediately
          deleted.
        </p>
        <p>
          PLEASE NOTE: Due to the nature of coupon redemptions, we will retain
          your phone number (with no other data) for a period of 1 year, during
          which time you will not be able to create a new account with the same
          phone number.
        </p>
        <p>
          To delete your account, go into the Snagm app and click on the
          "Settings" tab. Then click on "Delete Account".
        </p>
      </Row>
      <Row className="example-row">
        <Image className="example-image" src={example} fluid />
      </Row>
      <Row style={{ padding: 20, color: "#eeeef1" }}>
        <p>
          If there are any issues causing you to want to delete your account,
          we'd like the chance to talk to you about it first. Please contact us
          at <b>support@snagm.com</b>, and we'd be happy to help.
        </p>
      </Row>
      <Footer />
    </Container>
  );
}
