import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/info.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import LightHeader from "./lightHeader";
import Footer from "./footer";
import ReactGA from "react-ga4";

export default function Offer() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const email = queryParameters.get("email");
    setEmail(email);
  }, []);

  const acceptOffer = async () => {
    ReactGA.event("click_offer");
    const body = {
      email: email,
    };
    const bodyString = JSON.stringify(body);
    const url = "https://us-central1-snagm-b6068.cloudfunctions.net/founders";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: bodyString,
    });
    navigate("/thanks");
  };

  const rejectOffer = () => {
    ReactGA.event("reject_offer");
    navigate("/thanks");
  };

  return (
    <Container fluid className="video-container">
      <LightHeader />
      <Row className="video-main-row">
        <Col className="video-main-col">
          <Row className="super-max-row">
            <h3 className="super-max-title">
              Before you go, we have an exciting offer for you.
            </h3>
          </Row>
          <Row>
            <h2 className="video-subtitle">
              We have a limited number of Founder's Circle memberships still
              available.&nbsp;&nbsp;If you are seeing this page, then you
              qualify for this special offer.
            </h2>
          </Row>
          <Row className="explainer-row">
            <Col className="explainer-col-right">
              <Row>
                <Image
                  src="https://storage.googleapis.com/snagm-b6068.appspot.com/public/founders.png"
                  alt="founders-circle-logo"
                  className="founders-image"
                />
              </Row>
              <Row>
                <h3 className="explainer-signup-title offer-med-top">
                  Lifetime Service
                </h3>
              </Row>
              <Row>
                <p className="explainer-signup-subtext">
                  <b>Pay once and never pay again.</b>&nbsp;&nbsp;No monthly
                  fees, no annual fees, no hidden fees.&nbsp;&nbsp;For as long
                  as we offer our service, you will have access to all of our
                  premium features.
                </p>
              </Row>
              <Row>
                <h3 className="explainer-signup-title offer-med-top">
                  Custom Subscribe Code
                </h3>
              </Row>
              <Row>
                <p className="explainer-signup-subtext">
                  Our 5-character subscribe codes are unique to each restaurant,
                  and are assigned randomly.&nbsp;&nbsp;As a member of the
                  Founder's Circle, you will be able to choose your own custom
                  subscribe code.&nbsp;&nbsp;For example, instead of "FSD6F",
                  you could choose something like "PETES" or "BARBQ".
                </p>
              </Row>
              <Row>
                <h3 className="explainer-signup-title offer-med-top">
                  Private CEO Access
                </h3>
              </Row>
              <Row>
                <p className="explainer-signup-subtext">
                  You will have access to our CEO's private email address for
                  any questions or concerns.&nbsp;&nbsp;You will also have
                  access to a dedicated account manager who can assist you with
                  any issues.
                </p>
              </Row>
              <Row>
                <h3 className="explainer-signup-title offer-med-top">
                  Take 30 Days to Decide
                </h3>
              </Row>
              <Row>
                <p className="explainer-signup-subtext">
                  We'll invoice you with 30 days to pay.&nbsp;&nbsp;If you
                  decide not to proceed, simply ignore the invoice and you will
                  continue as a regular customer with your current promotion.
                </p>
              </Row>
              <Row>
                <h3 className="explainer-signup-title offer-med-top">
                  Join Today For Only $595
                </h3>
              </Row>
              <Row>
                <p className="explainer-signup-subtext">
                  As a regular customer, you would pay $540 per year - every
                  year.&nbsp;&nbsp;As a Founder's Circle member, you pay only
                  once, and never pay again.
                </p>
              </Row>
              <Row>
                <p className="explainer-signup-subtext">
                  <b>
                    This is a one-time offer and the price will never be lower.
                  </b>
                </p>
              </Row>
              <Row className="signup-button-row">
                <Button
                  onClick={() => acceptOffer()}
                  variant="custom"
                  className="video-signup-button"
                >
                  Yes, I want to join the Founder's Circle
                </Button>
              </Row>
              <Row className="signup-button-row">
                <Button
                  onClick={() => rejectOffer()}
                  variant="custom"
                  className="video-reject-button"
                >
                  No, thanks. I'll continue as a regular customer.
                </Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
}
