import React from "react";
import "../css/biz.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import Image from "react-bootstrap/Image";
import AdStart from "./adStart";
import Footer from "./footer";

export default function Advertise() {
  return (
    <Container fluid className="top-container">
      <Header />
      <Row className="hero-section">
        <Col className="hero-box">
          <Image src="/images/example-coupon2.jpg" fluid />
        </Col>
        <Col lg={true} className="hero-box">
          <h1 className="headline-main">
            Easily create and publish beautiful digital coupons
          </h1>
          <hr className="divider-line" />
          <h2 className="main-text">Reach out to Snagm users in your area</h2>
          <hr className="divider-line" />
          <h2 className="main-text">Get on their phone in a fun way</h2>
          <hr className="divider-line" />
          <h2 className="main-text">Digital coupons only redeemable once</h2>
          <hr className="divider-line" />
          <h2 className="main-text">
            Faster and easier than other advertising
          </h2>
        </Col>
      </Row>
      <Row className="hero-section-light">
        <Col lg={true} className="hero-box">
          <h1 className="light-text">You create your special coupon</h1>
          <h3 className="light-text">
            <hr className="divider-line-gray" />
            You decide what the offer will be
          </h3>
          <hr className="divider-line-gray" />
          <h3 className="light-text">You decide what the terms will be</h3>
          <hr className="divider-line-gray" />
          <h3 className="light-text">Professional graphic design included</h3>
        </Col>
        <Col className="hero-box">
          <Image src="/images/biz1.jpg" fluid />
        </Col>
      </Row>
      <AdStart />
      <Row className="hero-section">
        <Col className="hero-box">
          <Image src="/images/redeems.png" fluid />
        </Col>
        <Col lg={true} className="hero-box">
          <h1 className="headline-main">Track and measure your success</h1>
          <hr className="divider-line" />
          <h2 className="main-text">See coupon redemptions in real time</h2>
          <hr className="divider-line" />
          <h2 className="main-text">Monitor ongoing campaigns</h2>
          <hr className="divider-line" />
          <h2 className="main-text">Visualize coupon effectiveness</h2>
          <hr className="divider-line" />
          <h2 className="main-text">Immediate feedback on engagement</h2>
        </Col>
      </Row>
      <Row className="hero-section-light">
        <Col lg={true} className="hero-box">
          <h1 className="light-text">Up front, no surprises pricing</h1>
          <hr className="divider-line-gray" />
          <h3 className="light-text">You collect 100% of funds directly</h3>
          <hr className="divider-line-gray" />
          <h3 className="light-text">You owe no percentages of sales</h3>
          <hr className="divider-line-gray" />
          <h3 className="light-text">No per-coupon redemption fees</h3>
          <hr className="divider-line-gray" />
          <h3 className="light-text">
            Just one flat rate to bring in customers
          </h3>
        </Col>
        <Col className="hero-box">
          <Image src="/images/rates.jpg" fluid />
        </Col>
      </Row>
      <AdStart />
      <Row className="hero-section">
        <Col lg={true} className="hero-box">
          <h1 className="headline-main">How it works</h1>
          <hr className="divider-line" />
          <h2 className="main-text">Check out this short video</h2>
          <hr className="divider-line" />
          <h2 className="main-text">Click the Sign Up button</h2>
          <hr className="divider-line" />
          <h2 className="main-text">Get started in 60 seconds</h2>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
}
