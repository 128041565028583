import React from "react";
import { Routes, Route } from "react-router-dom";
import Scan from "./scan";
import DemoScan from "./demoScan";
import Landing from "./landing";
import Terms from "./terms";
import Privacy from "./privacy";
import NoPage from "./noPage";
import Advertise from "./advertise";
//import BizSignup from "./bizSignup.js";
import AdSignup from "./adSignup.js";
import Delete from "./delete.js";
import WaitList from "./waitlist.js";
//import Start from "./start.js";
import Details from "./details.js";
import Restaurants from "./restaurants.js";
//import SignupThanks from "./signupThanks.js";
import WaitThanks from "./waitThanks.js";
import Sorry from "./sorry.js";
import Unsub from "./unsub.js";
import Offer from "./offer.js";

export default function MainStack() {
  return (
    <Routes>
      <Route index element={<Landing />} />
      <Route path="s/:scancode" element={<Scan />} />
      <Route path="demo/" element={<DemoScan />} />
      <Route path="signup" element={<WaitList />} />
      <Route path="startads" element={<AdSignup />} />
      <Route path="advertise" element={<Advertise />} />
      <Route path="terms" element={<Terms />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="delete" element={<Delete />} />
      <Route path="thanks" element={<WaitThanks />} />
      <Route path="info" element={<WaitList />} />
      <Route path="start" element={<WaitList />} />
      <Route path="restaurants" element={<Restaurants />} />
      <Route path="details" element={<Details />} />
      <Route path="sorry" element={<Sorry />} />
      <Route path="unsub" element={<Unsub />} />
      <Route path="offer" element={<Offer />} />
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
}
