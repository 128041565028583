import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/info.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import LightHeader from "./lightHeader";
import Footer from "./footer";
import ReactPlayer from "react-player";
import ReactGA from "react-ga4";
import guarantee from "../assets/guarantee.png";

export default function Restaurants() {
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUS = async () => {
      try {
        const response = await fetch("https://api.country.is");
        const data = await response.json();
        console.log("verified country: ", data.country);
        if (data.country !== "US" && data.country !== "MX") {
          navigate("/sorry");
        }
      } catch (err) {
        //-- do nothing
      }
    };
    verifyUS();
  }, [navigate]);

  const goSignup = () => {
    ReactGA.event("click_signup");
    navigate("/signup");
  };

  const shortVideoStart = () => {
    ReactGA.event("short_video");
  };

  return (
    <Container fluid className="video-container">
      <LightHeader />
      <Row className="video-main-row">
        <Col className="video-main-col">
          <Row className="super-max-row">
            <h3 className="super-max-title">
              Attention:&nbsp;Restaurant Owners and Managers
            </h3>
          </Row>
          <Row className="super-title-row">
            <h3 className="super-title">
              Easily Boost Your Restaurant Traffic With One Simple Tool
            </h3>
          </Row>
          <Row>
            <h2 className="video-subtitle">
              Snagm Digital Coupons helps you attract new customers and create
              more repeat visits. Try it risk-free for a full year.
            </h2>
          </Row>
          <Row className="video-row">
            <Col>
              <ReactPlayer
                className="react-player"
                controls={true}
                playing={false}
                onStart={() => shortVideoStart()}
                width="100%"
                height="100%"
                url="https://storage.googleapis.com/snagm-b6068.appspot.com/video/snagm-digital-coupons.mp4"
              />
            </Col>
          </Row>
          <Row className="explainer-row">
            <Col className="explainer-col-left">
              <Row>
                <h6 className="explainer-title">Engage</h6>
              </Row>
              <Row>
                <p className="explainer-text">
                  Snagm is a free mobile app that provides users with visually
                  appealing digital coupons in a fun scrolling format. Users can
                  discover new places and subscribe to regular offers from their
                  favorites (that's you!)
                </p>
              </Row>
              <Row style={{ marginTop: 15 }}>
                <h6 className="explainer-title">Target</h6>
              </Row>
              <Row>
                <p className="explainer-text">
                  Attract new customers with DISCOVER coupons. Keep regulars
                  coming back more often with INBOX coupons. Set a BIRTHDAY
                  coupon to be delivered at the right time for each person.
                </p>
              </Row>
              <Row style={{ marginTop: 15 }}>
                <h6 className="explainer-title">Track</h6>
              </Row>
              <Row>
                <p className="explainer-text">
                  Digital coupons are easy to redeem, with no special equipment
                  needed. The app lets people use coupons only once. Secure
                  one-time use ensures a fair deal for everyone. Online charts
                  visually track your success.
                </p>
              </Row>
              <Row style={{ marginTop: 15 }}>
                <h6 className="explainer-title">Manage</h6>
              </Row>
              <Row>
                <p className="explainer-text-last">
                  Your online control panel lets you easily create and manage
                  your offers in less than 5 minutes per week, right from your
                  smartphone. No need to hire a designer - we provide 3
                  professional graphic designs per month at no extra cost. You
                  can also upload your own designs.
                </p>
              </Row>
            </Col>
            <Col className="explainer-col-right">
              <Row className="guarantee-row">
                <Image className="guarantee-image" src={guarantee} />
              </Row>
              <Row>
                <h3 className="explainer-signup-title">
                  Love It or Your Money Back
                </h3>
              </Row>
              <Row>
                <p className="explainer-signup-subtext">
                  If you're not satisfied with Snagm Digital Coupons for any
                  reason in your first year of service, we'll refund you 100%.
                </p>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <h3 className="explainer-signup-sub-title">
                  No Strings Attached
                </h3>
              </Row>
              <Row>
                <p className="explainer-signup-subtext">
                  No hidden costs, no transaction fees, and $0 setup fee.
                </p>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <h3 className="explainer-signup-sub-title">
                  Simple Pricing Plan
                </h3>
              </Row>
              <Row>
                <p className="explainer-signup-subtext">
                  Our pricing is simple and affordable. Just $15 per month (paid
                  annually) gets you unlimited coupons and redemptions.
                </p>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <h3 className="explainer-signup-sub-title">
                  Sign Up In 2 Minutes
                </h3>
              </Row>
              <Row>
                <p className="explainer-signup-subtext">
                  Just fill out the short form, let us know how many table tents
                  or stickers you need. All signage included at no cost.
                </p>
              </Row>
              <Row style={{ marginTop: 30 }} className="signup-button-row">
                <Button
                  onClick={() => goSignup()}
                  variant="custom"
                  className="video-signup-button"
                >
                  Get Started Now
                </Button>
              </Row>
              <Row>
                <p className="explainer-signup-microtext">
                  Questions?&nbsp;&nbsp;Email:&nbsp;&nbsp;
                  <b>support@snagm.com</b>
                </p>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <p className="launch-signup-subtext">
                  Mobile App Launching Now:
                </p>
                <p className="launch-signup-subtext">
                  <b>San Antonio, Texas</b>
                </p>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
}
