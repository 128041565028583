import React from "react";
import Col from "react-bootstrap/Col";
import LightHeader from "./lightHeader";
import "../css/landing.css";

export default function NoPage() {
  return (
    <React.Fragment>
      <LightHeader />
      <Col className="nopage-col">
        <h1 className="nopage-text">Page not found</h1>
        <p className="nopage-subtext">
          Sorry, we couldn't find the page you were looking for. Please try
          again.
        </p>
      </Col>
    </React.Fragment>
  );
}
