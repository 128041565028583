import React from "react";
import { BrowserRouter } from "react-router-dom";
import MainStack from "./components/mainStack";
import { initializeApp, getApps } from "firebase/app";
import { firebaseConfig } from "./config/firebase";
import ScrollToTop from "./components/scrollToTop.js";
import ReactGA from "react-ga4";

export default function App() {
  if (getApps.length === 0) {
    initializeApp(firebaseConfig);
    ReactGA.initialize("G-2YJ577HLRS");
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <MainStack />
    </BrowserRouter>
  );
}
